<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
	</div>
</template>

<script>

export default {
	name: 'shiptypepa-grid',
	components: {
	},
	data() {
		return {
			modelName: 'shiptypepa',
			modelColumnDefs: {
			}
		}
	}
}
</script>
